import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {useHistory} from 'react-router-dom'
// core components


import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import CardFooter from "components/Card/CardFooter.js";
import { NotificationManager } from "react-notifications";
import { useSpinnerContext } from "context/SpinnerContext";
import { MapContainer, TileLayer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import Map from "components/Map/Map";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function TableList() {
  const spinner = useSpinnerContext()
  let params = useHistory();
  const id = params.location.search.slice(4,params.location.search.length)
  
  const [branchName,setBranchName] = React.useState("")
  const [branchAddress,setBranchAddress] = React.useState("")
  const [branchDate,setBranchDate] = React.useState("")
  const [branchClock,setBranchClock] = React.useState("")
  const [branchNumber,setbranchNumber] = React.useState("")
  const [latLng, setLatLng] = React.useState({lat:0,lng:0})
  
  const classes = useStyles();
  
 



  

  


  React.useEffect(()=>{
     
      fetch(`${process.env.REACT_APP_API}/getsinglebranchar`,{
          method : "POST",
          headers : {
            "Authorization" : "Bearer " + localStorage.getItem('token')?.replace(/['"]+/g, ''),
            "Contect-type" : "application/json"
          },
          body : JSON.stringify({
              id : id
          })
      }).then(res=>res.json())
      .then(data=>{
          console.log(id)
          console.log(data.branch)
          setbranchNumber(data.branch.number)
          setBranchAddress(data.branch.address)
          setBranchClock(data.branch.clock)
          setBranchDate(data.branch.date)
          setBranchName(data.branch.name)
          setLatLng(JSON.parse(data.branch.location))
       
      })

     

  },[])

  const handleSubmit = () => {
    spinner.setShowSpinner(true)
      fetch(`${process.env.REACT_APP_API}/updatebranchar`,{
        method : 'POST',
        headers : {
          "Authorization" : "Bearer " + localStorage.getItem('token')?.replace(/['"]+/g, ''),
          "content-type":"application/json"
        },
        body : JSON.stringify({
          _id : id,
          name:branchName,
          address:branchAddress,
          clock:branchClock,
          date:branchDate,
          number:branchNumber,
          location : JSON.stringify(latLng)
        })
      })
      .then(res=>res.json())
      .then(data=>{
        NotificationManager.info(JSON.stringify(data))
        spinner.setShowSpinner(false)
      }).catch((err) => console.log(err));

    

   

  }
  return (
      <>
    <GridContainer>
    <GridItem xs={12} sm={12} md={12}>
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>ویرایش شعبه</h4>
          <p className={classes.cardCategoryWhite}> شعبه را ویرایش کنید</p>
        </CardHeader>
        <CardBody>     
            <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="عنوان شعبه"
                id="branchname"
                
                formControlProps={{
                    fullWidth: true,
                    
                }}
                inputProps ={{
                  onChange : (e)=>setBranchName(e.target.value)
                  ,value : branchName
                }}
                
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="آدرس شعبه"
                id="address"
                formControlProps={{
                    fullWidth: true,
                }}
                inputProps = {{
                  onChange : (e)=>setBranchAddress(e.target.value)
                  ,value : branchAddress
                }}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={`12`}>
              <CustomInput
                labelText="روز های کاری شعبه"
                id="date"
                formControlProps={{
                    fullWidth: true,
                }}
                inputProps = {{
                  onChange : (e)=>setBranchDate(e.target.value)
                  ,value : branchDate
                }}
                />
            </GridItem>
          </GridContainer>
   
          <GridContainer>

            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="شماره تماس"
                id="number"
                formControlProps={{
                    fullWidth: true,
                }}
                inputProps = {{
                  onChange : (e)=>setbranchNumber(e.target.value)
                  ,value : branchNumber
                }}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="ساعت کاری"
                id="clock"
                formControlProps={{
                    fullWidth: true,
                }}
                inputProps = {{
                  onChange : (e)=>setBranchClock(e.target.value)
                  ,value : branchClock
                }}
                />
            </GridItem>
          </GridContainer>
          <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="مختصات - lat"
                    id="lat"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (e) => setLatLng(prev=>{ return {lat:e.target.value,lng:prev.lng} }),
                      value : latLng.lat
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="مختصات - lng"
                    id="lng"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (e) => setLatLng(prev=>{ return {lng:e.target.value,lat:prev.lat} }),
                      value : latLng.lng
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div style={{width:"100%",height:"300px"}}>
                  <MapContainer center={[29.325702, 47.881488]} zoom={11} scrollWheelZoom={false}  >
                    <TileLayer
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      <Map setLatLng={setLatLng}/>
                  </MapContainer>
                      </div>
                </GridItem>
              </GridContainer>

 
        </CardBody>
        <CardFooter>
          <Button color="primary" onClick={handleSubmit}>ثبت تغییرات</Button>
        </CardFooter>
      </Card>
    </GridItem>
  </GridContainer>
   
    
                </>
  );
}
