/*eslint-disable*/
import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Store from "@material-ui/icons/Store";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
import BugReport from "@material-ui/icons/BugReport";
import Code from "@material-ui/icons/Code";
import Cloud from "@material-ui/icons/Cloud";
// core components
import Button from "components/CustomButtons/Button.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Tasks from "components/Tasks/Tasks.js";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

import PostsTable from '../PostsTable/PostsTable'
import ContactForm from '../ContactFormTable/ContactFormTable'
import Subscribe from '../Subscribe/Subscribe'




import styles from "assets/jss/material-dashboard-react/views/rtlStyle.js";




const useStyles = makeStyles(styles);

export default function RTLPage() {
  const classes = useStyles();

  const [countBlogAr, setCountBlogAr] = React.useState(0)
  const [countBlogEn, setCountBlogEn] = React.useState(0)


  const [countBranchAr, setCountBranchAr] = React.useState(0)
  const [countBranchEn, setCountBranchEn] = React.useState(0)

  const [countContactForm, setCountContactForm] = React.useState(0)
  const [countSubsribe, setCountSubsribe] = React.useState(0)

  const [countCurrency, setCountCurrency] = React.useState(0)
  const [countSlider, setCountSlider] = React.useState(0)

  const [countVisits, setCountVisits] = React.useState(0)


  React.useEffect(() => {

    fetch(`${process.env.REACT_APP_API}/getbranchsen`, {
      method: "POST",

    }).then(res => res.json())
      .then(json => {
        console.log(json)
        setCountBranchEn(json.branchs.length)

      })


    fetch(`${process.env.REACT_APP_API}/getbranchsar`, {
      method: "POST",

    }).then(res => res.json())
      .then(json => {
        console.log(json)
        setCountBranchAr(json.branchs.length)
      })

    fetch(`${process.env.REACT_APP_API}/getblogar`, {
      method: "POST",

    }).then(res => res.json())
      .then(json => {
        console.log(json)
        setCountBlogAr(json.posts.length)
      })

    fetch(`${process.env.REACT_APP_API}/getblogen`, {
      method: "POST",

    }).then(res => res.json())
      .then(json => {
        console.log(json)
        setCountBlogEn(json.posts.length)
      })


    fetch(`${process.env.REACT_APP_API}/getcontactform`, {
      method: "POST",
      headers: {
        "Authorization": "Bearer " + localStorage.getItem('token')?.replace(/['"]+/g, '')
      }
    }).then(res => res.json())
      .then(json => {
        console.log(json)
        setCountContactForm(json.forms.length)
      })


    fetch(`${process.env.REACT_APP_API}/getcurrencies`, {
      method: "POST",

    }).then(res => res.json())
      .then(json => {
        console.log(json)
        setCountCurrency(json.currencies.length)
      })


    fetch(`${process.env.REACT_APP_API}/getsubscribe`, {
      method: "POST",
      headers: {
        "Authorization": "Bearer " + localStorage.getItem('token')?.replace(/['"]+/g, '')
      }
    }).then(res => res.json())
      .then(json => {
        console.log(json)
        setCountSubsribe(json.subscribe.length)
      })


    fetch(`${process.env.REACT_APP_API}/getsliders`, {
      method: "POST",
    }).then(res => res.json())
      .then(json => {
        console.log(json)
        setCountSlider(json.sliders.length)
      })
      

      fetch(`${process.env.REACT_APP_API}/getvisits`, {
        
        method: "POST",
        headers: {
          "Authorization": "Bearer " + localStorage.getItem('token')?.replace(/['"]+/g, '')
        }
      }).then(res => res.json())
        .then(json => {
          console.log(json)
          setCountVisits(json)
        })





  }, [])
  return (
    <div>
      <GridContainer>
        {/* blog ar */}
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>content_copy</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>مقالات منتشر شده عربی</p>
              <h3 className={classes.cardTitle}>
                {countBlogAr}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                همه زمان ها
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        {/* blog en */}
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>مقالات منتشر شده انگلیسی</p>
              <h3 className={classes.cardTitle}>{countBlogEn}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                همه زمان ها
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        {/* branch en */}
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>تعداد شعب (انگلیسی)</p>
              <h3 className={classes.cardTitle}>{countBranchEn}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <LocalOffer />
                توسط ادمین
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        {/*branch ar  */}
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>تعداد شعب (عربی)</p>
              <h3 className={classes.cardTitle}>{countBranchAr}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <LocalOffer />
                توسط ادمین
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        {/* visit */}
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Accessibility />
              </CardIcon>
              <p className={classes.cardCategory}>تعداد بازدید کل</p>
              <h3 className={classes.cardTitle}>+{countVisits}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                همه زمان ها
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        {/* subscribe */}
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>content_copy</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>تعداد مشترکین ایمیل</p>
              <h3 className={classes.cardTitle}>
                {countSubsribe}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                همه زمان ها
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        {/* contact form */}
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>تعداد فرم های دریافتی</p>
              <h3 className={classes.cardTitle}>{countContactForm}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <LocalOffer />
                توسط ادمین
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        {/* slider */}
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Accessibility />
              </CardIcon>
              <p className={classes.cardCategory}>تعداد اسلاید های وارد شده</p>
              <h3 className={classes.cardTitle}>{countSlider}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                همه زمان ها
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        {/* currency */}
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>تعداد ارز های وارد شده</p>
              <h3 className={classes.cardTitle}>{countCurrency}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <LocalOffer />
                توسط ادمین
              </div>
            </CardFooter>
          </Card>
        </GridItem>




      </GridContainer>






      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <PostsTable />
        </GridItem>



        <GridItem xs={12} sm={12} md={6}>
          <ContactForm />
          <Subscribe />
        </GridItem>

      </GridContainer>



    </div>
  );
}
