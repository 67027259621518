import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { NotificationManager } from "react-notifications";
import { useSpinnerContext } from "context/SpinnerContext";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function TableList() {
    const spinner = useSpinnerContext()
    const [table,setTable] = React.useState([])
    const classes = useStyles();
    const [counter,setCounter] = React.useState(1)
  
    const handleDelete = (id) => {
      spinner.setShowSpinner(true)
      fetch(`${process.env.REACT_APP_API}/deletecontactform`,{
            method : "POST",
            headers : {
              "Authorization" : "Bearer " + localStorage.getItem('token')?.replace(/['"]+/g, '')
            },
            body : JSON.stringify({id})
        }).then(res=>res.json())
        .then(data=>{
          NotificationManager.info(JSON.stringify(data))
          spinner.setShowSpinner(false)
          setCounter(prev=>prev+1)
        }).catch((err) => console.log(err));
    }
  
    React.useEffect(()=>{
      console.log(localStorage.getItem('token'))
        fetch(`${process.env.REACT_APP_API}/getcontactform`,{
            method : "POST",
            headers : {
              "Authorization" : "Bearer " + localStorage.getItem('token')?.replace(/['"]+/g, '')
            }
        }).then(res=>res.json())
        .then(json=>{
            console.log(json)
            let res = json.forms.map(row => { return [...Object.values(row)]})
            res = res.map(arr=>  [...arr] )
            console.log(res)
            setTable(res)
        })
  
    },[counter])
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>لیست فرم های دریافتی</h4>
            <p className={classes.cardCategoryWhite}>
              در این قسمت تمامی فرم های ارسال شده توسط کاربران  قرار دارد همچنین شما میتوانید آن را   حذف کنید
            </p>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="primary"
              tableHead={["عملیات","پیام","موضوع","تاریخ","ایمیل","نام","آی دی"].reverse()}
              tableData={table}
              handleDelete={handleDelete}
            />
          </CardBody>
        </Card>
      </GridItem>
     </GridContainer>
  );
}
