import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { useHistory } from 'react-router-dom'
import { MapContainer, TileLayer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
// import { icon as lIcon } from "leaflet";
// core components


import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import CardFooter from "components/Card/CardFooter.js";
import { NotificationManager } from "react-notifications";
import { useSpinnerContext } from "context/SpinnerContext";
import Map from "components/Map/Map";


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function TableList() {

  const spinner = useSpinnerContext()
  let history = useHistory();
  const [tableAr, setTableAr] = React.useState([])
  const [tableEn, setTableEn] = React.useState([])
  const [latLng, setLatLng] = React.useState({lat:0,lng:0})

  const [branchName, setBranchName] = React.useState("")
  const [branchAddress, setBranchAddress] = React.useState("")
  const [branchDate, setBranchDate] = React.useState("")
  const [branchClock, setBranchClock] = React.useState("")
  const [branchNumber, setbranchNumber] = React.useState("")
  const [branchLanguage, setBranchLanguage] = React.useState("AR")
  const [counter, setCounter] = React.useState(1)
  const classes = useStyles();

  console.log(branchNumber)



  const handleDeleteAr = (id) => {
    spinner.setShowSpinner(true)
    fetch(`${process.env.REACT_APP_API}/deletebranchar`, {
      method: "POST",
      body: JSON.stringify({ id }),
      headers: {
        "Authorization": "Bearer " + localStorage.getItem('token')?.replace(/['"]+/g, '')
      }
    }).then(res => res.json())
      .then(data => {
        NotificationManager.info(JSON.stringify(data))
        spinner.setShowSpinner(false)
        setCounter(prev => prev + 1)
      }).catch((err) => console.log(err));
  }

  const handleDeleteEn = (id) => {
    spinner.setShowSpinner(true)
    fetch(`${process.env.REACT_APP_API}/deletebranchen`, {
      method: "POST",
      body: JSON.stringify({ id }),
      headers: {
        "Authorization": "Bearer " + localStorage.getItem('token')?.replace(/['"]+/g, '')
      }
    }).then(res => res.json())
      .then(data => {
        NotificationManager.info(JSON.stringify(data))
        spinner.setShowSpinner(false)
        setCounter(prev => prev + 1)
      }).catch((err) => console.log(err));
  }
  const handleUpdateAr = (id) => {

    history.push(`/rtl/updatebranchar?id=${id}`)

  }

  const handleUpdateEn = (id) => {
    history.push(`/rtl/updatebranchen?id=${id}`)
  }


  React.useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/getbranchsar`, {
      method: "POST",
      headers: {
        "Authorization": "Bearer " + localStorage.getItem('token')?.replace(/['"]+/g, '')
      }
    }).then(res => res.json())
      .then(json => {

        let res = json.branchs.map(row => { return [...Object.values(row)] })
        res = res.map(arr => [...arr])
        console.log(res)
        setTableAr(res)
      })

    fetch(`${process.env.REACT_APP_API}/getbranchsen`, {
      method: "POST",
      headers: {
        "Authorization": "Bearer " + localStorage.getItem('token')?.replace(/['"]+/g, '')
      }
    }).then(res => res.json())
      .then(json => {

        let res = json.branchs.map(row => { return [...Object.values(row)] })
        res = res.map(arr => [...arr])
        console.log(res)
        setTableEn(res)
      })

  }, [counter])

  const handleSubmit = () => {
    spinner.setShowSpinner(true)
    if (branchLanguage === "EN") {
      fetch(`${process.env.REACT_APP_API}/addbranchen`, {
        method: 'POST',
        headers: {
          "Authorization": "Bearer " + localStorage.getItem('token')?.replace(/['"]+/g, '')
        },
        body: JSON.stringify({
          name: branchName,
          address: branchAddress,
          clock: branchClock,
          date: branchDate,
          number: branchNumber,
          location : JSON.stringify(latLng)
        })
      })
        .then(res => res.json())
        .then(data => {
          NotificationManager.info(JSON.stringify(data))
          spinner.setShowSpinner(false)
          setCounter(prev=>prev+1)
        }).catch((err) => console.log(err));

    }

    if (branchLanguage === "AR") {
      fetch(`${process.env.REACT_APP_API}/addbranchar`, {
        method: 'POST',
        headers: {
          "Authorization": "Bearer " + localStorage.getItem('token')?.replace(/['"]+/g, '')
        },
        body: JSON.stringify({
          name: branchName,
          address: branchAddress,
          clock: branchClock,
          date: branchDate,
          number: branchNumber,
          location : JSON.stringify(latLng)
        })
      })
        .then(res => res.json())
        .then(data => {
          NotificationManager.info(JSON.stringify(data))
          spinner.setShowSpinner(false)
          setCounter(prev=>prev+1)
        }).catch((err) => console.log(err));

    }

  }
  return (
    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>افزودن شعبه</h4>
              <p className={classes.cardCategoryWhite}>یک شعبه ایجاد کنید</p>
            </CardHeader>
            <CardBody>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>

                  <label htmlFor="">  زبان : </label>
                  <select name="language" id="language" onChange={(e) => setBranchLanguage(e.target.value)}>
                    <option value="AR">عربی</option>
                    <option value="EN">انگلیسی</option>
                  </select>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="عنوان شعبه"
                    id="branchname"
                    formControlProps={{
                      fullWidth: true,

                    }}
                    inputProps={{
                      onChange: (e) => setBranchName(e.target.value)
                    }}

                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="آدرس شعبه"
                    id="address"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (e) => setBranchAddress(e.target.value)
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={`12`}>
                  <CustomInput
                    labelText="روز های کاری شعبه"
                    id="date"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (e) => setBranchDate(e.target.value)
                    }}
                  />
                </GridItem>
              </GridContainer>

              <GridContainer>

                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="شماره تماس"
                    id="number"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (e) => setbranchNumber(e.target.value)
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="ساعت کاری"
                    id="clock"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (e) => setBranchClock(e.target.value)
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="مختصات - lat"
                    id="lat"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (e) => setLatLng(prev=>{ return {lat:e.target.value,lng:prev.lng} }),
                      value : latLng.lat
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="مختصات - lng"
                    id="lng"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      onChange: (e) => setLatLng(prev=>{ return {lng:e.target.value,lat:prev.lat} }),
                      value : latLng.lng
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <div style={{width:"100%",height:"300px"}}>
                  <MapContainer center={[29.325702, 47.881488]} zoom={11} scrollWheelZoom={false}  >
                    <TileLayer
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      <Map setLatLng={setLatLng}/>
                  </MapContainer>
                      </div>
                </GridItem>
              </GridContainer>



            </CardBody>
            <CardFooter>
              <Button color="primary" inputProps={{ onClick: handleSubmit }} onClick={handleSubmit}>افزودن به لیست شعب</Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>لیست شعب (عربی)</h4>
              <p className={classes.cardCategoryWhite}>
                در این قسمت میتوانید لیست شعب را حذف یا ویرایش کنید
              </p>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={["ای دی", "نام شعبه", "آدرس", "ساعت کاری", "روزی های کاری", "شماره تماس", "موقعیت","عملیات"]}
                tableData={tableAr}
                handleDelete={handleDeleteAr}
                handleEdit={handleUpdateAr}

              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>لیست شعب (انگلیسی)</h4>
              <p className={classes.cardCategoryWhite}>
                در این قسمت میتوانید لیست شعب را حذف یا ویرایش کنید
              </p>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="primary"
                tableHead={["ای دی", "نام شعبه", "آدرس", "ساعت کاری", "روزی های کاری", "شماره تماس", "موقعیت","عملیات"]}
                tableData={tableEn}
                handleDelete={handleDeleteEn}
                handleEdit={handleUpdateEn}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>




    </>
  );
}


