/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {SpinnerContext} from './context/SpinnerContext'
import React from "react";
import ReactDOM from "react-dom";
import App from './App'


ReactDOM.render(
  <> 
  <SpinnerContext>
  <App/>
  </SpinnerContext>
  </>
 ,
  document.getElementById("root")
);
