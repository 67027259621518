import React from 'react'



const spinnerContext = React.createContext()
export const  SpinnerContext = ({children} : any) => {
    const [showSpinner, setShowSpinner] = React.useState(false)
    return (
        <spinnerContext.Provider value={{ showSpinner, setShowSpinner }}>
            {children}
        </spinnerContext.Provider>
    )
    
}


export const useSpinnerContext = () => {
    const {showSpinner,setShowSpinner} = React.useContext(spinnerContext)
    return {showSpinner,setShowSpinner}
}



