
import React from "react";
import { BrowserRouter, Route, Switch,Redirect } from "react-router-dom";
import {NotificationContainer} from 'react-notifications';
// core components
// import Admin from "layouts/Admin.js";
import RTL from "layouts/RTL.js";
import Login from "views/Login/Login.js";
import "assets/css/material-dashboard-react.css?v=1.10.0";
import 'react-notifications/lib/notifications.css';
import 'assets/css/app.css'
function App() {


  
    return (
      <>
        <BrowserRouter>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/rtl" component={RTL} />
          <Redirect from="/" to="/rtl/dashboard" />
        </Switch>
      </BrowserRouter>
      <NotificationContainer/>
     
      </>
    )
}

export default App
