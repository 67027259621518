import React from 'react'
import { useHistory } from 'react-router-dom'
import './Login.css'
function Login() {
  const [form, setForm] = React.useState({ email: "", password: "" })
  const history = useHistory()

  const submitForm = () => {

    console.log(form)
    fetch(`${process.env.REACT_APP_API}/signin`, {
      "content-type": "application/json",
      "method": "post",
      "body": JSON.stringify(form)
    })
      .then(res => res.json())
      .then(data => {
        if (data.token) {
          localStorage.setItem('token', JSON.stringify(data.token))
          history.push("/rtl/dashboard")

        }else{
          alert(JSON.stringify(data))
        }


        console.log(data)
      })
  }
  return (
    <div className="auth-wrapper">

      <form className="navbar-light" onSubmit={(e) => { e.preventDefault() }} >
        <h3>Sign In</h3>

        <div className="form-group">
          <label>Email address</label>
          <input type="email" className="form-control" placeholder="Enter email" onChange={(e) => { setForm(prev => { return { ...prev, email: e.target.value } }) }} />
        </div>

        <div className="form-group">
          <label>Password</label>
          <input type="password" className="form-control" placeholder="Enter email" onChange={(e) => { setForm(prev => { return { ...prev, password: e.target.value } }) }} />
        </div>

        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input type="checkbox" className="custom-control-input" id="customCheck1" />
            <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
          </div>
        </div>

        <button type="button" onClick={submitForm} className="btn btn-primary btn-block login-btn " >Login</button>

      </form>

    </div>



  )
}

export default Login
