import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";


import ImageUpload from 'image-upload-react'
//important for getting nice style.
import 'image-upload-react/dist/index.css'
import { NotificationManager } from "react-notifications";
import { useSpinnerContext } from "context/SpinnerContext";

import { convertToHTML } from 'draft-convert';
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function UserProfile() {
  const spinner = useSpinnerContext()
  var d = new Date();
var n = d.getUTCDate() + "-" + d.getUTCMonth() + "-" + d.getUTCFullYear();
  const classes = useStyles();
  const [imageSrc, setImageSrc] = React.useState()
  const [imageFile, setImageFile] = React.useState()
  const [postTitle, setPostTitle] = React.useState()
  const [postDate] = React.useState(n)
  const [postAuthor, setPostAuthor] = React.useState()
  const [postLanguage, setPostLanguage] = React.useState("AR")


    const [postDescription, setPostDescription] = React.useState(() =>
      EditorState.createEmpty()
    );

    
    const convertContentToHTML = (state) => {
      let currentContentAsHTML = convertToHTML(state.getCurrentContent());
      return currentContentAsHTML
    }

    
    const handleImageSelect = (e) => {
      console.log(e.target.files[0])
      setImageSrc(URL.createObjectURL(e.target.files[0]))
      setImageFile(e.target.files[0])
      
      
      
    }
    
    
    const handleSubmission = () => {
      spinner.setShowSpinner(true)
      console.log('enter handle sub')
      const data = new FormData();
      data.append("file", imageFile);
      data.append("upload_preset", "dg9sp0wev");
      data.append("cloud_name", "dg9sp0wev");
      fetch("https://api.cloudinary.com/v1_1/dg9sp0wev/image/upload", {
        method: "POST",
        body: data
        
        
      })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        fetch(`${process.env.REACT_APP_API}/addpost`,{
          method:"POST",
          headers : {
            "Authorization" : "Bearer " + localStorage.getItem('token')?.replace(/['"]+/g, '')
          },
          body : JSON.stringify({
            image : data.secure_url,
            title :postTitle ,
            description : convertContentToHTML(postDescription),
            date :postDate ,
            language : postLanguage,
            author : postAuthor,
          })
        }).then(res=>res.json())
        .then(data=>{
          
          NotificationManager.info(JSON.stringify(data))
          spinner.setShowSpinner(false)
        }).catch((err) => console.log(err));
        
      })
      .catch((err) => console.log(err));
      
    };

    
    console.log(convertContentToHTML(postDescription))


    return (
      <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>ایجاد مقاله</h4>
              <p className={classes.cardCategoryWhite}>یک مقاله ایجاد کنید</p>
            </CardHeader>
            <CardBody>     
                <GridContainer>               
                   <GridItem xs={12} sm={12} md={12}>
                <ImageUpload
      handleImageSelect={handleImageSelect}
      imageSrc={imageSrc}
      setImageSrc={setImageSrc}
      style={{
        width: "100%",
        height: "200px",
        background: 'gold'
      }}
    />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="عنوان مقاله"
                    id="title"
                    inputProps={{onChange:(e)=>{setPostTitle(e.target.value)}}}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>

              </GridContainer>
       
              <GridContainer>

                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="نویسنده"
                    id="author"
                    inputProps={{onChange:(e)=>{setPostAuthor(e.target.value)}}}
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>

              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} >
                  {/* <CustomInput
                    labelText="متن مقاله را در این قسمت وارد کنید"
                    inputProps={{multiline: true, rows: 5,onChange:(e)=>{setPostDescription(e.target.value)}}}
                    id="description"
                    formControlProps={{
                      fullWidth: true,
                    }}
                 
                  /> */}
                  <div style={{minHeight:"250px"}}>
<Editor  onEditorStateChange={setPostDescription}   editorState={postDescription}  />
                  </div>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <select name="language" id="language" onChange={(e)=>{setPostLanguage(e.target.value)}}>
                    <option value="AR">عربی</option>
                    <option value="EN">انگلیسی</option>
                  </select>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="primary" inputProps={{onClick:handleSubmission}} onClick={handleSubmission}>انتشار</Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
