import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import {useHistory} from 'react-router-dom'
import { NotificationManager } from "react-notifications";
import { useSpinnerContext } from "context/SpinnerContext";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function TableList() {
  const spinner = useSpinnerContext()
  const history = useHistory()
    const [tableEn,setTableEn] = React.useState([])
    const [tableAr,setTableAr] = React.useState([])
    const [counter,setCounter] = React.useState(1)
  const classes = useStyles();


  const handleDeleteAr = (id) => {
    spinner.setShowSpinner(true)
    fetch(`${process.env.REACT_APP_API}/deleteblogar`,{
          method : "POST",
          headers : {
            "Authorization" : "Bearer " + localStorage.getItem('token')?.replace(/['"]+/g, '')
          },
          body : JSON.stringify({id})
      }).then(res=>res.json())
      .then(data=>{
        NotificationManager.info(JSON.stringify(data))
        spinner.setShowSpinner(false)
        setCounter(prev=>prev+1)
      }).catch((err) => console.log(err));
  }

  const handleUpdateAr = (id) => {
    history.push(`/rtl/updateblogar?id=${id}`)
  }
  const handleUpdateEn = (id) => {
    history.push(`/rtl/updateblogen?id=${id}`)
  }

  const handleDeleteEn = (id) => {
    spinner.setShowSpinner(true)
    fetch(`${process.env.REACT_APP_API}/deleteblogen`,{
          method : "POST",
          headers : {
            "Authorization" : "Bearer " + localStorage.getItem('token')?.replace(/['"]+/g, '')
          },
          body : JSON.stringify({id})
      }).then(res=>res.json())
      .then(data=>{
        NotificationManager.info(JSON.stringify(data))
        spinner.setShowSpinner(false)
        setCounter(prev=>prev+1)
      }).catch((err) => console.log(err));
  }


  React.useEffect(()=>{
      fetch(`${process.env.REACT_APP_API}/getblogen`,{
          method : "POST",
          headers : {
            "Authorization" : "Bearer " + localStorage.getItem('token')?.replace(/['"]+/g, '')
          }
          
      }).then(res=>res.json())
      .then(json=>{
          
          let res = json.posts.map(row => { return [...Object.values(row)]})
          res = res.map(arr=>  [...arr] )
          console.log(res)
          setTableEn(res)
      })

      fetch(`${process.env.REACT_APP_API}/getblogar`,{
          method : "POST",
          headers : {
            "Authorization" : "Bearer " + localStorage.getItem('token')?.replace(/['"]+/g, '')
          }
      }).then(res=>res.json())
      .then(json=>{
          
          let res = json.posts.map(row => { return [...Object.values(row)]})
          res = res.map(arr=>  [...arr] )
          console.log(res)
          setTableAr(res)
      })

  },[counter])
  return (
      <>
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}> لیست مقالات (انگلیسی )</h4>
            <p className={classes.cardCategoryWhite}>
              در این قسمت تمامی مقالات منتشر شده قرار دارد همچنین شما میتوانید آن را ویرایش یا حذف کنید
            </p>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="primary"
              tableHead={[
               "ای دی"
               ,"عنوان"
               ,"متن مقاله"
              , "تاریخ انتشار"
              , "نویسنده"
              ,"تصویر"
              , "عملیات"
              
            ]}
              tableData={tableEn}
              handleDelete = {handleDeleteEn}
              handleEdit = {handleUpdateEn}
             
            />
          </CardBody>
        </Card>
      </GridItem>
     </GridContainer>
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>لیست مقالات (عربی)</h4>
            <p className={classes.cardCategoryWhite}>
              در این قسمت تمامی مقالات منتشر شده قرار دارد همچنین شما میتوانید آن را ویرایش یا حذف کنید
            </p>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="primary"
              tableHead={[
                "ای دی"
                ,"عنوان"
                ,"متن مقاله"
               , "نویسنده"
               , "تاریخ انتشار"
               ,"تصویر"
               , "عملیات"
            ]}
              handleDelete = {handleDeleteAr}
              handleEdit= {handleUpdateAr}
              tableData={tableAr}
            />
          </CardBody>
        </Card>
      </GridItem>
     </GridContainer>
     </>
  );
}
