/*!

=========================================================
* Material Dashboard React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
// core components/views for Admin layout
// import DashboardPage from "views/Dashboard/Dashboard.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import PostsTable from "views/PostsTable/PostsTable.js";
import UpdateBlogAr from "views/PostsTable/UpdateBlogAr.js";
import UpdateBlogEn from "views/PostsTable/UpdateBlogEn.js";
import ContactFormTable from "views/ContactFormTable/ContactFormTable.js";
// import Typography from "views/Typography/Typography.js";
// import Icons from "views/Icons/Icons.js";
import Users from "views/Users/Users.js";
import Subscribe from "views/Subscribe/Subscribe.js";
import Branchs from "views/Branchs/Branchs.js";
import UpdateBranchAr from "views/Branchs/UpdateAr.js"; 
import UpdateBranchEn from "views/Branchs/UpdateEn.js";
import Website from "views/Website/Website.js";
import Slider from "views/Slider/Slider.js";
import Price from "views/Price/Price.js";
import AboutUs from "views/AboutUs/AboutUs.js";
import Services from "views/Services/Services.js";
 
// core components/views for RTL layout
import RTLPage from "views/RTLPage/RTLPage.js";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "صفحه اصلی",
    rtlName: "صفحه اصلی",
    icon: Dashboard,
    component: RTLPage,
    layout: "/rtl",
  },
  {
    path: "/addpost",
    name: "ایجاد مقاله",
    rtlName: "ایجاد مقاله",
    icon: Person,
    component: UserProfile,
    layout: "/rtl",
  },
  {
    path: "/poststable",
    name: "مدیریت مقالات",
    rtlName: "مدیریت مقالات",
    icon: "content_paste",
    component: PostsTable,
    layout: "/rtl",
  },
  {
    path: "/contactform",
    name: "فرم های دریافتی",
    rtlName: "فرم های دریافتی",
    icon: LibraryBooks,
    component: ContactFormTable,
    layout: "/rtl",
  },
  {
    path: "/branchs",
    name: "لیست شعب",
    rtlName: "لیست شعب",
    icon: BubbleChart,
    component: Branchs,
    layout: "/rtl",
  },
  {
    path: "/updatebranchar",
    name: "ویرایش شعبه",
    rtlName: "ویرایش شعبه ",
    icon: BubbleChart,
    component: UpdateBranchAr,
    layout: "/rtl",
    dontShowInSidebar : true
  },
  {
    path: "/updatebranchen",
    name: "ویرایش شعبه",
    rtlName: "ویرایش شعبه ",
    icon: BubbleChart,
    component: UpdateBranchEn,
    layout: "/rtl",
    dontShowInSidebar : true
  },
  {
    path: "/updateblogar",
    name: "ویرایش شعبه",
    rtlName: "ویرایش شعبه ",
    icon: BubbleChart,
    component: UpdateBlogAr,
    layout: "/rtl",
    dontShowInSidebar : true
  },
  {
    path: "/updateblogen",
    name: "ویرایش شعبه",
    rtlName: "ویرایش شعبه ",
    icon: BubbleChart,
    component: UpdateBlogEn,
    layout: "/rtl",
    dontShowInSidebar : true
  },
  {
    path: "/website",
    name: "مشاهده وبسایت",
    rtlName: "مشاهده وبسایت",
    icon: BubbleChart,
    component: Website,
    layout: "/rtl",
  },
  {
    path: "/subscribe",
    name: "مدیریت کاربران عضو خبرنامه",
    rtlName: "مدیریت کاربران عضو خبرنامه",
    icon: LocationOn,
    component: Subscribe,
    layout: "/rtl",
  },
  {
    path: "/slider",
    name: "مدیریت اسلایدر",
    rtlName: "مدیریت اسلایدر",
    icon: Notifications,
    component: Slider,
    layout: "/rtl",
  },
  {
    path: "/users",
    name: "لیست کاربران",
    rtlName: "لیست کاربران",
    icon: Notifications,
    component: Users,
    layout: "/rtl",
  },
  {
    path: "/price",
    name: "لیست ارزها",
    rtlName: "لیست ارزها",
    icon: Notifications,
    component: Price,
    layout: "/rtl",
  },
  {
    path: "/aboutus",
    name: "درباره ما ",
    rtlName: "درباره ما ",
    icon: Notifications,
    component: AboutUs,
    layout: "/rtl",
  },
  {
    path: "/services",
    name: "خدمات ما",
    rtlName: "خدمات ما",
    icon: Notifications,
    component: Services,
    layout: "/rtl",
  }
];

export default dashboardRoutes;
