import {useMapEvents} from 'react-leaflet'
function Map({setLatLng}) {
    // eslint-disable-next-line no-unused-vars
    const map = useMapEvents({
        click: (e) => {
          console.log(e.latlng)
          setLatLng(e.latlng)
        },
        locationfound: (location) => {
          console.log('location found:', location)
        },
      })
      return null
}

export default Map
