import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";


import ImageUpload from 'image-upload-react'
//important for getting nice style.
import 'image-upload-react/dist/index.css'
import { NotificationManager } from "react-notifications";
import { useSpinnerContext } from "context/SpinnerContext";


import { convertToHTML } from 'draft-convert';
import { ContentState, convertFromHTML } from 'draft-js'
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";



const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function UserProfile() {
  const spinner = useSpinnerContext()
  const classes = useStyles();
  const [imageSrc, setImageSrc] = React.useState()
  const [imageFile, setImageFile] = React.useState()


  const [postDescription, setPostDescription] = React.useState(() =>
  EditorState.createEmpty()
);




  const [imageSrc2, setImageSrc2] = React.useState()
  const [imageFile2, setImageFile2] = React.useState()
  const [postDescription2, setPostDescription2] = React.useState(() =>
  EditorState.createEmpty()
);


  console.log(imageSrc)
  console.log('imageFile ', imageFile)


  console.log(imageSrc2)
  console.log('imageFile2 ', imageFile2)


  const convertContentToHTML = (state) => {
    let currentContentAsHTML = convertToHTML(state.getCurrentContent());
    return currentContentAsHTML
  }

  
  const handleImageSelect = (e) => {
    console.log(e.target.files[0])
    setImageSrc(URL.createObjectURL(e.target.files[0]))
    setImageFile(e.target.files[0])



  }


  const handleImageSelect2 = (e) => {
    console.log(e.target.files[0])
    setImageSrc2(URL.createObjectURL(e.target.files[0]))
    setImageFile2(e.target.files[0])



  }


  const handleSubmission = () => {
    spinner.setShowSpinner(true)
    console.log('enter handle sub')

    if (imageFile !== undefined) {
      const data = new FormData();
      data.append("file", imageFile);
      data.append("upload_preset", "dg9sp0wev");
      data.append("cloud_name", "dg9sp0wev");
      fetch("https://api.cloudinary.com/v1_1/dg9sp0wev/image/upload", {
        method: "POST",
        body: data,


      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          fetch(`${process.env.REACT_APP_API}/addourservicesar`, {
            method: "POST",
            headers: {
              "Authorization": "Bearer " + localStorage.getItem('token')?.replace(/['"]+/g, ''),
              "content-type": "application/json"
            },
            body: JSON.stringify({
              image: data.secure_url,
              description: convertContentToHTML(postDescription),
            })
          }).then(res=>res.json())
          .then(data=>{
            NotificationManager.info(JSON.stringify(data))
            spinner.setShowSpinner(false)
          }).catch((err) => console.log(err));

        })
        .catch((err) => console.log(err));
    } else {
      fetch(`${process.env.REACT_APP_API}/addourservicesar`, {
        method: "POST",
        headers: {
          "Authorization": "Bearer " + localStorage.getItem('token')?.replace(/['"]+/g, ''),
          "content-type": "application/json"
        },
        body: JSON.stringify({
          image: imageSrc,
          description: convertContentToHTML(postDescription),
        })
      }).then(res=>res.json())
      .then(data=>{
        NotificationManager.info(JSON.stringify(data))
        spinner.setShowSpinner(false)
      }).catch((err) => console.log(err));
    }


  };
  const handleSubmission2 = () => {
    console.log('enter handle sub')
    spinner.setShowSpinner(true)
    if (imageFile2 !== undefined) {
      const data = new FormData();
      data.append("file", imageFile2);
      data.append("upload_preset", "dg9sp0wev");
      data.append("cloud_name", "dg9sp0wev");
      fetch("https://api.cloudinary.com/v1_1/dg9sp0wev/image/upload", {
        method: "POST",
        body: data,
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);
          fetch(`${process.env.REACT_APP_API}/addourservicesen`, {
            method: "POST",
            headers: {
              "Authorization": "Bearer " + localStorage.getItem('token')?.replace(/['"]+/g, ''),
              "content-type": "application/json"
            },
            body: JSON.stringify({
              image: data.secure_url,
              description: convertContentToHTML(postDescription2),
            })
          }).then(res=>res.json())
          .then(data=>{
            NotificationManager.info(JSON.stringify(data))
            spinner.setShowSpinner(false)
          }).catch((err) => console.log(err));

        })
        .catch((err) => console.log(err));
    } else {
      fetch(`${process.env.REACT_APP_API}/addourservicesen`, {
        method: "POST",
        headers: {
          "Authorization": "Bearer " + localStorage.getItem('token')?.replace(/['"]+/g, ''),
          "content-type": "application/json"
        },
        body: JSON.stringify({
          image: imageSrc2,
          description: convertContentToHTML(postDescription2),
        })
      }).then(res=>res.json())
      .then(data=>{
        NotificationManager.info(JSON.stringify(data))
        spinner.setShowSpinner(false)
      }).catch((err) => console.log(err));
    }


  };




  React.useEffect(() => {

    fetch(`${process.env.REACT_APP_API}/getourservicesar`, {
      method: "POST",
      headers: {
        "Authorization": "Bearer " + localStorage.getItem('token')?.replace(/['"]+/g, ''),
        "Contect-type": "application/json"
      },
    }).then(res => res.json())
      .then(data => {

console.log(data)
    
        setPostDescription(EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(data?.aboutus[0]?.description)
          )
        ))
        setImageSrc(data?.aboutus[0]?.image)


      })
    fetch(`${process.env.REACT_APP_API}/getourservicesen`, {
      method: "POST",
      headers: {
        "Authorization": "Bearer " + localStorage.getItem('token')?.replace(/['"]+/g, ''),
        "Contect-type": "application/json"
      },
    }).then(res => res.json())
      .then(data => {

        console.log(data)
        
        setPostDescription2(EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(data?.aboutus[0]?.description)
          )
        ))
        setImageSrc2(data?.aboutus[0]?.image)


      })



  }, [])

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>ویرایش خدمات ما (عربی)</h4>
              <p className={classes.cardCategoryWhite}> صفحه خدمات ما را ویرایش کنید</p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <ImageUpload
                    handleImageSelect={handleImageSelect}
                    imageSrc={imageSrc}
                    setImageSrc={setImageSrc}
                    style={{
                      width: "100%",
                      height: "200px",
                      background: 'gold'
                    }}
                  />
                </GridItem>


              </GridContainer>


              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  {/* <CustomInput
                    labelText="متن مقاله را در این قسمت وارد کنید"
                    inputProps={{ multiline: true, rows: 5, onChange: (e) => { setPostDescription(e.target.value) }, value: postDescription }}
                    id="description"
                    formControlProps={{
                      fullWidth: true,
                    }}

                  /> */}
                  <div style={{minHeight:"250px"}}>
<Editor  onEditorStateChange={setPostDescription}   editorState={postDescription}  />
                  </div>
                </GridItem>
              </GridContainer>

            </CardBody>
            <CardFooter>
              <Button color="primary" inputProps={{ onClick: handleSubmission }} onClick={handleSubmission}>ذخیره تغییرات</Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>ویرایش خدمات ما (انگلیسی)</h4>
              <p className={classes.cardCategoryWhite}> صفحه خدمات ما را ویرایش کنید</p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <ImageUpload
                    handleImageSelect={handleImageSelect2}
                    imageSrc={imageSrc2}
                    setImageSrc={setImageSrc2}
                    style={{
                      width: "100%",
                      height: "200px",
                      background: 'gold'
                    }}
                  />
                </GridItem>


              </GridContainer>


              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  {/* <CustomInput
                    labelText="متن مقاله را در این قسمت وارد کنید"
                    inputProps={{ multiline: true, rows: 5, onChange: (e) => { setPostDescription2(e.target.value) }, value: postDescription2 }}
                    id="description"
                    formControlProps={{
                      fullWidth: true,
                    }}

                  /> */}

<div style={{minHeight:"250px"}}>
<Editor  onEditorStateChange={setPostDescription2}   editorState={postDescription2}  />
                  </div>
                </GridItem>
              </GridContainer>

            </CardBody>
            <CardFooter>
              <Button color="primary" inputProps={{ onClick: handleSubmission2 }} onClick={handleSubmission2}>ذخیره تغییرات</Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
