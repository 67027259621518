/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import styles from "assets/jss/material-dashboard-react/views/iconsStyle.js";
import ImageUpload from 'image-upload-react'
import Button from "components/CustomButtons/Button.js";
import { NotificationManager } from "react-notifications";
import { useSpinnerContext } from "context/SpinnerContext";
const useStyles = makeStyles(styles);

export default function Icons() {
  const spinner = useSpinnerContext()
  const classes = useStyles();
  const [imageSrc, setImageSrc] = React.useState()
  const [imageFile, setImageFile] = React.useState()
  const [imagesList, setImagesList] = React.useState([])
  const [counter,setCounter] = React.useState(0)

  console.log(imagesList)

  const handleImageSelect = (e) => {
    console.log(e.target.files[0])
    setImageSrc(URL.createObjectURL(e.target.files[0]))
    setImageFile(e.target.files[0])



  }

  const deleteimage = (id) => {
    spinner.setShowSpinner(true)
    fetch(`${process.env.REACT_APP_API}/deleteslider`, {
      method: "POST",
      headers: {
        "Authorization": "Bearer " + localStorage.getItem('token')?.replace(/['"]+/g, '')
      },
      body: JSON.stringify({
        id: id,
      })
    }).then(res => res.json())
      .then(data => {
        NotificationManager.info(JSON.stringify(data))
        spinner.setShowSpinner(false)
        
      }).then(data => {
        fetch(`${process.env.REACT_APP_API}/getsliders`, {
          method: "POST",
          headers: {
            "Authorization": "Bearer " + localStorage.getItem('token')?.replace(/['"]+/g, '')
          }
        }).then(data => data.json())
          .then(res => setImagesList(res.sliders))
      })


  }


  const handleSubmission = () => {
    spinner.setShowSpinner(true)
    console.log('enter handle sub')
    const data = new FormData();
    data.append("file", imageFile);
    data.append("upload_preset", "dg9sp0wev");
    data.append("cloud_name", "dg9sp0wev");
    fetch("https://api.cloudinary.com/v1_1/dg9sp0wev/image/upload", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        fetch(`${process.env.REACT_APP_API}/addslider`, {
          method: "POST",
          headers: {
            "Authorization": "Bearer " + localStorage.getItem('token')?.replace(/['"]+/g, '')
          },
          body: JSON.stringify({
            image: data.secure_url,
          })
        })
          .then(res => res.json())
          .then(data => {
            NotificationManager.info(JSON.stringify(data))
            spinner.setShowSpinner(false)
            setCounter(prev=>prev+1)
          }).catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));

  };



  React.useEffect(() => {
    fetch(`${process.env.REACT_APP_API}/getsliders`, {
      method: "POST",


    }).then(data => data.json())
      .then(res => setImagesList(res.sliders))

  }, [counter])
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card plain>
          <CardHeader plain color="primary">
            <h4 className={classes.cardTitleWhite}>مدیریت اسلایدر</h4>

          </CardHeader>
          <CardBody >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <ImageUpload
                  handleImageSelect={handleImageSelect}
                  imageSrc={imageSrc}
                  setImageSrc={setImageSrc}
                  style={{
                    width: "100%",
                    height: "200px",
                    background: 'gold'
                  }}

                />
                <Button color="primary" onClick={handleSubmission}>ثبت عکس انتخاب شده</Button>
              </GridItem>


            </GridContainer>


            <GridContainer>

              {imagesList.map(img => (<GridItem xs={12} sm={6} md={3}>
                <div style={{ position: 'relative' }} onClick={() => { deleteimage(img._id) }}>
                  <span style={{ padding: "1rem", backgroundColor: "RGBA(149,0,117,0.09)", borderRadius: "15px", cursor: "pointer", color: "white", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)" }}>حذف عکس</span>
                  <img src={img.image} width={"100%"} height={"150px"}  style={{ marginBottom: "20px" }} />
                </div>  </GridItem>))}





            </GridContainer>

          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
