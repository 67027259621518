import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Table from "components/Table/Table"


import ImageUpload from 'image-upload-react'
//important for getting nice style.
import 'image-upload-react/dist/index.css'
import { NotificationManager } from "react-notifications";
import { useSpinnerContext } from "context/SpinnerContext";


const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
    },
};

const useStyles = makeStyles(styles);

export default function UserProfile() {
    const spinner = useSpinnerContext()
    const classes = useStyles();
    const [imageSrc, setImageSrc] = React.useState()
    const [imageFile, setImageFile] = React.useState()
    const [currencyName, setCurrencyName] = React.useState("")
    const [currencyPrice, setCurrencyPrice] = React.useState("")
    const [table,setTable] = React.useState([])
    const [counter,setCounter] = React.useState(1)


    console.log(imageSrc)

    const handleImageSelect = (e) => {
        console.log(e.target.files[0])
        setImageSrc(URL.createObjectURL(e.target.files[0]))
        setImageFile(e.target.files[0])



    }

    const handleDelete = (id) => {
        spinner.setShowSpinner(true)
        fetch(`${process.env.REACT_APP_API}/deletecurrency`,{
              method : "POST",
              headers : {
                "Authorization" : "Bearer " + localStorage.getItem('token')?.replace(/['"]+/g, '')
              },
              body : JSON.stringify({id})
          }).then(res=>res.json())
          .then(data=>{
            NotificationManager.info(JSON.stringify(data))
            spinner.setShowSpinner(false)
            setCounter(prev=>prev+1)
          }).catch((err) => console.log(err));
      }

    React.useEffect(()=>{
        fetch(`${process.env.REACT_APP_API}/getcurrencies`,{
            method : "POST",
            headers : {
              "Authorization" : "Bearer " + localStorage.getItem('token')?.replace(/['"]+/g, '')
            }
        }).then(res=>res.json())
        .then(json=>{           
            let res = json.currencies.map(row => { return [...Object.values(row)]})
            res = res.map(arr=>  [...arr] )
            console.log(res)
            setTable(res)
        })
    },[counter])


    const handleSubmission = () => {
        console.log('enter handle sub')
        spinner.setShowSpinner(true)
        const data = new FormData();
        data.append("file", imageFile);
        data.append("upload_preset", "dg9sp0wev");
        data.append("cloud_name", "dg9sp0wev");
        fetch("https://api.cloudinary.com/v1_1/dg9sp0wev/image/upload", {
            method: "POST",           
            body: data,
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data);
                fetch(`${process.env.REACT_APP_API}/addcurrency`, {
                    method: "POST",
                    headers : {
                      "Authorization" : "Bearer " + localStorage.getItem('token')?.replace(/['"]+/g, '')
                    },
                    body: JSON.stringify({
                        image: data.secure_url,
                        currencyName,
                        currencyPrice,

                    })
                }).then(res=>res.json())
                .then(data=>{
                  NotificationManager.info(JSON.stringify(data))
                  spinner.setShowSpinner(false)
                  setCounter(prev=>prev+1)
                }).catch((err) => console.log(err));

            })
            .catch((err) => console.log(err));

    };

    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary">
                            <h4 className={classes.cardTitleWhite}>ایجاد ارز</h4>
                            <p className={classes.cardCategoryWhite}>یک ارز جدید ایجاد کنید</p>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <ImageUpload
                                        handleImageSelect={handleImageSelect}
                                        imageSrc={imageSrc}
                                        setImageSrc={setImageSrc}
                                        style={{
                                            width: "100%",
                                            height: "200px",
                                            background: 'gold'
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomInput
                                        labelText="نام ارز  را وارد کنید"
                                        id="title"
                                        inputProps={{ onChange: (e) => { setCurrencyName(e.target.value) } }}
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                    />
                                </GridItem>

                            </GridContainer>

                            <GridContainer>

                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomInput
                                        labelText="قیمت ارز را وارد کنید"
                                        id="author"
                                        inputProps={{ onChange: (e) => { setCurrencyPrice(e.target.value) } }}
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                    />
                                </GridItem>

                            </GridContainer>

                        </CardBody>
                        <CardFooter>
                            <Button color="primary" inputProps={{ onClick: handleSubmission }} onClick={handleSubmission}>انتشار</Button>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>

            <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}> لیست ارز ها </h4>
            <p className={classes.cardCategoryWhite}>
              در این قسمت تمامی ارز های منتشر شده قرار دارد همچنین شما میتوانید آن را ویرایش یا حذف کنید
            </p>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="primary"
              tableHead={[
               "ای دی"
               ,"تصویر"
              , "نام ارز"
              , "قیمت"
              , "عملیات "
            ]}
              tableData={table}
              handleDelete={handleDelete}
            />
          </CardBody>
        </Card>
      </GridItem>
     </GridContainer>
   
        </div>
    );
}
